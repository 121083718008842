<template>
<section>
    <div class="col-md-12 loginform">
        <h2 style="margin-top: 5px;">ล็อกอินเข้าสู่ระบบB2B</h2>
        <div class="row">
            <div class="col-12" style="margin: 0px;background-color: white;padding:20px 20px;border-radius: 7px;height: auto;">
                <div class="form-group">
                    <label for="xuserid"><i class="fas fa-envelope"></i> อีเมล/USERID</label>
                    <input type="email" class="form-control" name="userid" placeholder="USERID" v-model="dataToSend.USERNAME">
                </div>
                <div class="form-group">
                    <label for="passw"><i class="fas fa-key"></i> รหัสผ่าน</label>
                    <input type="password" class="form-control" name="password" placeholder="Password" v-model="dataToSend.PASSWORD">
                </div>
                <button type="button" class="btn-login" @click="loginCustB2B">
                    <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">เข้าสู่ระบบ</strong>
                </button>

                <div id="message" class="info info-default">

                </div>
            </div>
        </div>

    </div>
</section>
</template>

<script>
import * as serviceAPI from '../services/API.service';
import * as serviceMain from '../services/main.service';

export default {
    name: 'LoginformB2B',
    data: function () {
        return {
            dataToSend: {
                USERNAME: "",
                PASSWORD: ""
            },
        }
    },
    props: [

    ],
    mounted() {
        this.cklogIn()

    },
    methods: {
        cklogIn: async function () {
            if (this.$store.getters['auth/getloggedIn']) {
                if(!this.getRef){
                    this.getRefB2B()
                }
                this.$router.push('/');
            }
        },
        async loginCustB2B() {
            try {
                let data = {
                    USERNAME: this.dataToSend.USERNAME,
                    PASSWORD: this.dataToSend.PASSWORD,
                    MCID: this.$store.getters['customer/getQTMCID'],
                };
                let getAPI = await serviceAPI.call_API('post', `directsale/loginCustB2B`, data);
                let getToken = getAPI.data.access_token
                this.$store.dispatch('auth/login', getToken)
                this.$store.dispatch('auth/setUSERMAILB2B', this.dataToSend.USERNAME)
                this.$store.dispatch("auth/setISTB", 'true');
                let getData = getAPI.data.dataRef.dbitems[0];
                let datainfo = getAPI.data.dataCusInfo.dbitems[0];
                let dataCusDS = getAPI.data.dataCusDS
                let getCUSTREF = getData.CUSTREF;
                let getNITEM = getData.SUMQNT;
                let getCURSTC = getData.CURSTC.trim();
                this.$store.dispatch('customer/setQTCUSTINFO', {
                    CNAME: datainfo.CNAME,
                    MOBILE: datainfo.MOBILE,
                    EMAIL: datainfo.EMAIL,
                    T1C: datainfo.T1C,
                    VIPID: datainfo.VIPID,
                    SALESOURCE: this.$store.getters['auth/getUser'].QTSALESOURCE,
                    EMPLOYEEBUSHORTCODE: '',
                    EMPLOYEEID: '',
                    ISSTAFF: '',
                })
                this.$store.dispatch('customer/setEPCUSTINFO', dataCusDS)
                this.$store.dispatch('service/setStcShowStock',dataCusDS.STORE)
                this.$store.dispatch('service/setStShowStock',dataCusDS.STCODE)
                this.$store.dispatch('customer/setQTCUSTREF', getCUSTREF)
                this.$store.dispatch('customer/setQTSHOPITEM', getNITEM)
                await this.setDataStoreByUser()
                if (getCURSTC) {
                    this.$store.dispatch('service/setQTDFSTORE', getCURSTC)
                    let getAPI = await serviceAPI.call_API('post', 'service/findBU', {
                        "STCODE": getCURSTC
                    }, 'auth');
                    let getBU = getAPI.data.dbitems[0].BU;
                    this.$store.dispatch('customer/setQTBU', getBU)
                    let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
                    let locationsTEMP = getQTSAVESTORE.split('|');
                    let locations = locationsTEMP.map(e => e.split(','));
                    let getQTStoreNow = getCURSTC
                    let QTStoreName = locations.filter(e => e[0] == getQTStoreNow);
                    let getStoreName = QTStoreName[0][1];
                    this.$store.dispatch('service/setQTSTORENAME', getStoreName)
                } else {
                    if(!this.qtDfStore) {
                        $('#mapModal').modal('show')
                    }
                }
                this.$router.push('/');
                // this.$store.dispatch('auth/login', getToken)
                // let getData = getAPI.data.dbitems[0].listdata;
                // this.qtSaveStore = getData
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },
        async getRefB2B() {
            try {
                let data = {
                    USERNAME: this.qtUserId,
                    MCID: this.$store.getters['customer/getQTMCID'],
                };
                let getAPI = await serviceAPI.call_API('post', `directsale/getCustRefB2B`, data);
                this.$store.dispatch('auth/setUSERMAILB2B', this.dataToSend.USERNAME)
                // if(this.$store.getters['auth/getUser']){

                // }
                // let getToken = getAPI.data.access_token
                // this.$store.dispatch('auth/login', getToken)
                // console.log(getToken);
                let getData = getAPI.data.dataRef.dbitems[0];
                let datainfo = getAPI.data.dataCusInfo.dbitems[0];
                // console.log(getAPIcusInfo);
                // let datainfo = getAPIcusInfo.data.dbitems[0]
                let dataCusDS = getAPI.data.dataCusDS
                let getCUSTREF = getData.CUSTREF;
                // console.log(getCUSTREF)
                let getNITEM = getData.SUMQNT;
                let getCURSTC = getData.CURSTC.trim();
                this.$store.dispatch('customer/setQTCUSTINFO', {
                    CNAME: datainfo.CNAME,
                    MOBILE: datainfo.MOBILE,
                    EMAIL: datainfo.EMAIL,
                    T1C: datainfo.T1C,
                    VIPID: datainfo.VIPID,
                    SALESOURCE: this.$store.getters['auth/getUser'].QTSALESOURCE,
                    EMPLOYEEBUSHORTCODE: '',
                    EMPLOYEEID: '',
                    ISSTAFF: '',
                })
                this.$store.dispatch('customer/setEPCUSTINFO', dataCusDS)
                this.$store.dispatch('service/setStcShowStock',dataCusDS.STORE)
                this.$store.dispatch('service/setStShowStock',dataCusDS.STCODE)
                this.$store.dispatch('customer/setQTCUSTREF', getCUSTREF)
                this.$store.dispatch('customer/setQTSHOPITEM', getNITEM)
                if (getCURSTC) {
                    this.$store.dispatch('service/setQTDFSTORE', getCURSTC)
                    let getAPI = await serviceAPI.call_API('post', 'service/findBU', {
                        "STCODE": getCURSTC
                    }, 'auth');
                    let getBU = getAPI.data.dbitems[0].BU;
                    this.$store.dispatch('customer/setQTBU', getBU)

                    let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
                    let locationsTEMP = getQTSAVESTORE.split('|');
                    let locations = locationsTEMP.map(e => e.split(','));
                    let getQTStoreNow = getCURSTC
                    let QTStoreName = locations.filter(e => e[0] == getQTStoreNow);
                    let getStoreName = QTStoreName[0][1];
                    this.$store.dispatch('service/setQTSTORENAME', getStoreName)
                } else {
                    if(!this.qtDfStore) {
                        $('#mapModal').modal('show')
                    }
                }
                this.$router.push('/');
                // this.$store.dispatch('auth/login', getToken)
                // let getData = getAPI.data.dbitems[0].listdata;
                // this.qtSaveStore = getData
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },
        async setDataStoreByUser() {
            let data = {
              USERID: this.qtUserId,
              SALESOURCE: this.qtSalesource
            }
            let getAPI = await serviceAPI.call_API('post', `service/store`, data);
            let getData = getAPI.data.dbitems[0].listdata;
            this.qtSaveStore = getData
        },
    },
    computed: {
      qtSaveStore: {
        get() {
          return this.$store.getters['service/getQTSAVESTORE']
        },
        set(value) {
          this.$store.dispatch('service/setQTSAVESTORE', value)
        }
      },
      qtUserId: function () {
        return this.$store.getters['auth/getQTUSERID']
      },
      qtSalesource: function () {
        return this.$store.getters['auth/getQTSALESOURCE']
      },
      getRef:function(){
        return this.$store.getters['customer/getQTCUSTREF']
      },
    }
}
</script>

<style scoped>
.btn-login {
    background-color: #55246c;
    width: 100%;
    border: none;
    color: white;
    padding: 2px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 0px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 4px;
}

.loginform {
    max-width: 400px;
    margin: 50px auto;
}
</style>
