<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
          <FindCartB2B />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import FindCartB2B from '@/components/FindCartB2B.vue'

export default {
  name: 'FindUserB2B',
  components: {
    FindCartB2B,
  },
  props: {

  },
    data: function () {
    return {
   
    }
  },
  mounted() {
    document.title = `${process.env.VUE_APP_TITLE} - FindUser`
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
    document.head.appendChild(recaptchaScript)
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CRY3HG283C');
    this.isFromCheckoutConfirm()
  },
  methods: {
    isFromCheckoutConfirm(){
      if(this.$route.query.confirm =='success'){
        console.log('clear cust');
        this.$store.dispatch('customer/clearAfterConfirmCheckoutB2B')
        this.$store.dispatch('service/clearAfterConfirmCheckout')
      }
    }

  },
}
</script>
