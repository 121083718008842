<template>
<section>
    <div class="col-md-12">
    </div>
</section>
</template>

<script>
import * as serviceAPI from '../services/API.service';
import * as serviceMain from '../services/main.service';

export default {
    name: 'FindCartB2B',
    data: function () {
        return {
            dataToSend: {
                USERNAME: "",
                PASSWORD: ""
            },
        }
    },
    props: [

    ],
    mounted() {
        this.getRefB2B()
    },
    methods: {
        async getRefB2B() {
            try {
                console.log('get ref B2B');
                // this.$router.push("/twdb2b/login");
                // this.$store.dispatch("auth/logout");
                // this.$store.dispatch("customer/clearAfterLogout");
                // this.$store.dispatch("service/clearAfterLogout");
                
                let data = {
                    USERNAME: this.$store.getters['auth/getUSERMAILB2B'],
                    MCID: this.$store.getters['customer/getQTMCID'],
                };
                console.log(data);
                let getAPI = await serviceAPI.call_API('post', `directsale/getCustRefB2B`, data);
                console.log(getAPI);
                // this.$store.dispatch('auth/setUSERMAILB2B', this.dataToSend.USERNAME)
                // if(this.$store.getters['auth/getUser']){

                // }
                // let getToken = getAPI.data.access_token
                // this.$store.dispatch('auth/login', getToken)
                // console.log(getToken);
                let getData = getAPI.data.dataRef.dbitems[0];
                console.log(getData);
                let datainfo = getAPI.data.dataCusInfo.dbitems[0];
                // console.log(getAPIcusInfo);
                // let datainfo = getAPIcusInfo.data.dbitems[0]
                console.log(datainfo);
                let dataCusDS = getAPI.data.dataCusDS
                console.log(dataCusDS);
                let getCUSTREF = getData.CUSTREF;
                // console.log(getCUSTREF)
                let getNITEM = getData.SUMQNT;
                let getCURSTC = getData.CURSTC.trim();
                console.log(this.$store.getters['auth/getUser']);
                this.$store.dispatch('customer/setQTCUSTINFO', {
                    CNAME: datainfo.CNAME,
                    MOBILE: datainfo.MOBILE,
                    EMAIL: datainfo.EMAIL,
                    T1C: datainfo.T1C,
                    VIPID: datainfo.VIPID,
                    SALESOURCE: this.$store.getters['auth/getUser'].QTSALESOURCE,
                    EMPLOYEEBUSHORTCODE: '',
                    EMPLOYEEID: '',
                    ISSTAFF: '',
                })
                this.$store.dispatch('customer/setEPCUSTINFO', dataCusDS)
                this.$store.dispatch('service/setStcShowStock',dataCusDS.STORE)
                console.log(dataCusDS.STORE);
                this.$store.dispatch('customer/setQTCUSTREF', getCUSTREF)
                this.$store.dispatch('customer/setQTSHOPITEM', getNITEM)
                if (getCURSTC) {
                    this.$store.dispatch('service/setQTDFSTORE', getCURSTC)
                    let getAPI = await serviceAPI.call_API('post', 'service/findBU', {
                        "STCODE": getCURSTC
                    }, 'auth');
                    let getBU = getAPI.data.dbitems[0].BU;
                    this.$store.dispatch('customer/setQTBU', getBU)

                    let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
                    let locationsTEMP = getQTSAVESTORE.split('|');
                    let locations = locationsTEMP.map(e => e.split(','));
                    let getQTStoreNow = getCURSTC
                    let QTStoreName = locations.filter(e => e[0] == getQTStoreNow);
                    let getStoreName = QTStoreName[0][1];
                    this.$store.dispatch('service/setQTSTORENAME', getStoreName)
                } else {
                    if(!this.qtDfStore) {
                        $('#mapModal').modal('show')
                    }
                }
                this.$router.push('/');
                // this.$store.dispatch('auth/login', getToken)
                // let getData = getAPI.data.dbitems[0].listdata;
                // this.qtSaveStore = getData
                
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },
    },
    computed: {
      qtSaveStore: {
        get() {
          return this.$store.getters['service/getQTSAVESTORE']
        },
        set(value) {
          this.$store.dispatch('service/setQTSAVESTORE', value)
        }
      },
      qtUserId: function () {
        return this.$store.getters['auth/getQTUSERID']
      },
      qtSalesource: function () {
        return this.$store.getters['auth/getQTSALESOURCE']
      },
      getRef:function(){
        return this.$store.getters['customer/getQTCUSTREF']
      },
    }
}
</script>

<style scoped>
.btn-login {
    background-color: #55246c;
    width: 100%;
    border: none;
    color: white;
    padding: 2px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 0px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 4px;
}

.loginform {
    max-width: 400px;
    margin: 50px auto;
}
</style>
