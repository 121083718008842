<template>
  <section>
    <img style="vertical-align: middle;height: auto;width: 100%;" src="/img/howdeli.png" width="1920" height="1080">
  </section>
</template>

<script>
export default {
    name: "FooterBuyingPolicy"
}
</script>

<style>

</style>