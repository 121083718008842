<template>
  <div class="container">
    <LoginformB2B />
  </div>
</template>

<script>
// @ is an alias to /src
import LoginformB2B from '@/components/LoginformB2B.vue'

export default {
  name: 'LoginB2B',
  components: {
    LoginformB2B
  },
  props: {

  },
    data: function () {
    return {
     
    }
  },
  mounted() {
    document.title = `${process.env.VUE_APP_TITLE} - Login`
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
    document.head.appendChild(recaptchaScript)
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CRY3HG283C');
    this.isFromCheckoutConfirm()
    this.$store.dispatch("auth/setISTB", 'true');
  },
  methods: {
    isFromCheckoutConfirm(){
      if(this.$route.query.confirm =='success'){
        this.$store.dispatch('customer/clearAfterConfirmCheckout')
        this.$store.dispatch('service/clearAfterConfirmCheckout')
      }
    }
  },
}
</script>
